import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  Paper,
  Typography,
  Box,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FaBriefcase } from "react-icons/fa";
import styled from "@emotion/styled";

const StyledTimeline = styled(Timeline)(({ theme }) => ({
  padding: "6px 16px",
  "& .MuiTimelineItem-root": {
    "&:before": {
      flex: 0,
      padding: 0,
    },
  },
}));

const StyledTimelineContent = styled(TimelineContent)(
  ({ theme, isMobile }) => ({
    margin: "0",
    padding: "0 16px",
    width: isMobile ? "100%" : "45%",
  })
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "20px",
  backgroundColor: "rgba(25, 25, 25, 0.95)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(155, 164, 181, 0.1)",
  "&:hover": {
    backgroundColor: "rgba(25, 25, 25, 0.85)",
    border: "1px solid #9BA4B5",
  },
  "& .MuiTypography-root": {
    textAlign: "left",
  },
  "& ul": {
    margin: "12px 0",
    paddingLeft: "20px",
    paddingRight: 0,
    listStyle: "disc",
  },
  "& li": {
    textAlign: "left",
    marginBottom: "6px",
    position: "relative",
    fontSize: "0.875rem",
  },
  "& .chip-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    justifyContent: "flex-start",
  },
}));

const ExperienceTimeline = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledTimeline position={isMobile ? "right" : "alternate"}>
      {items.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{
              margin: "auto 0",
              padding: "8px 16px",
              width: isMobile ? "auto" : "45%",
              textAlign: isMobile ? "left" : index % 2 === 0 ? "right" : "left",
              display: isMobile ? "none" : "block",
            }}>
            <Typography variant="body2" color="text.secondary">
              {item.period}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              sx={{
                bgcolor: "background.paper",
                border: "2px solid #9BA4B5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
              }}>
              <FaBriefcase style={{ color: "#9BA4B5" }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <StyledTimelineContent isMobile={isMobile}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  color: "#fff",
                  mb: 1,
                  fontSize: isMobile ? "1.1rem" : "1.25rem",
                }}>
                {item.title}
              </Typography>
              {isMobile && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#9BA4B5",
                    mb: 1,
                    fontSize: "0.875rem",
                  }}>
                  {item.period}
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#9BA4B5",
                  mb: 1,
                  fontSize: isMobile ? "0.9rem" : "1rem",
                }}>
                {item.company} ({item.location})
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#9BA4B5",
                  mb: 2,
                  lineHeight: 1.6,
                  fontSize: isMobile ? "0.875rem" : "0.9rem",
                }}>
                {item.description}
              </Typography>
              {item.responsibilities && (
                <Box
                  component="ul"
                  sx={{ fontSize: isMobile ? "0.875rem" : "0.9rem" }}>
                  {item.responsibilities.map((resp, idx) => (
                    <Typography
                      key={idx}
                      component="li"
                      variant="body2"
                      sx={{
                        color: "#9BA4B5",
                        fontSize: "inherit",
                      }}>
                      {resp}
                    </Typography>
                  ))}
                </Box>
              )}
              {item.technologies && (
                <Box className="chip-container">
                  {item.technologies.map((tech, idx) => (
                    <Chip
                      key={idx}
                      label={tech}
                      size="small"
                      sx={{
                        bgcolor: "rgba(155, 164, 181, 0.1)",
                        color: "#F1F6F9",
                        fontSize: "0.7rem",
                        height: "24px",
                      }}
                    />
                  ))}
                </Box>
              )}
            </StyledPaper>
          </StyledTimelineContent>
        </TimelineItem>
      ))}
    </StyledTimeline>
  );
};

export default ExperienceTimeline;
